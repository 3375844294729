<template>
  <!-- TestCafe helper route -->
  <div class="home-login">
    <v-card elevation="0">

      <v-card-title>Home Page (TestCafe Role login)</v-card-title>
      <v-card-subtitle>Quick access to various programs</v-card-subtitle>
      <v-card-actions>
        <ProgramList />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

import ProgramList from '@/components/common/ProgramList.vue';

export default {
  name: 'HomeLogin',
  components: {
    ProgramList,
  },
  methods: {
    ...mapActions([
      'reAuth',
    ]),
  },
  created() {
    this.reAuth();
  },
};
</script>
